$maxWidth: 1200px;
$mainColor: #0274FF;


// Company页面
.pCompany { width: 1200px; display: flex; flex-wrap: wrap; margin: 0 auto; padding-bottom: 90px;
  img { width: 224px; height: 120px; margin-right: 20px; padding-bottom: 40px;
    &:nth-of-type(5n) { margin-right: 0; }
  }
}


// Foot页面
.pFoot { 
  &_t{ height: 232px; text-align: center; background: url('../../img/foot-bg.png'); background-size: 100% 100%; color: #fff;
    &_text { padding: 60px 0 30px; font-size: 28px; font-weight: bold; }
    &_btn { width: 116px; height: 42px; line-height: 42px; text-align: center; background: $mainColor; border-radius: 4px; margin: 0 auto; cursor: pointer; }
  }

  .pFoot_b_box { height: 340px; width: 100%;  background-color: #0B1D2E; box-sizing: border-box; padding: 62px 0 34px; color: #fff; }
  &_b { width: 1200px; justify-content: space-between; margin: 0 auto; display: flex;
    .pFoot_b_title { font-size: 18px; position: relative; padding-bottom: 42px;
      &::before { position: absolute; top: -8px; width: 26px; content: ''; height: 2px; background: #fff;  }
    }
    .pFoot_b_item_text { padding-bottom: 12px; display: flex; }
    .pFoot_b_item_mobile { font-size: 28px; padding-bottom: 22px; }
    ul {
      li { padding-bottom: 12px; cursor: pointer; }
    }
    .pFoot_b_item_qr{  
      img { width: 106px; height: 106px; }
      p { margin-top: 8px; text-indent: 24px; }
    }
  } 
  .pFoot_cl_t { text-indent: 66px; text-align: left; color: #fff; font-size: 28px; line-height: 232px; }
  .copyRight { height: 60px; display: flex; justify-content: center; color: #fff; background: #030E19; line-height: 60px;
    div { margin-right: 80px; }
    a { color: #fff; text-decoration: none; 
      &:hover { opacity: .6; }
    }
  }
}


// 体验弹窗

.pPop_box {
  position: fixed; z-index: 9999; background: rgba(0,0,0,.5); left: 0; top: 0; width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; 
  .pPop {
    width: 610px; box-sizing: border-box;   background: #fff;; border-radius: 4px; padding: 60px 60px 40px 48px; position: relative;

    .pPop_cancel { position: absolute; right: 10px; top: 10px; width: 21px; height: 21px; padding: 10px; cursor: pointer; }
    .pPop_cancel1 {
      position: absolute; right: 10px; top: 10px;  padding: 10px; cursor: pointer; font-size: 22px; color: rgb(170, 170, 170); font-weight: bold;
      &::after {
        content: '\2715';
      }
    }

    .pPop_form {
      display: flex; align-items: center; margin-bottom: 20px;
      .pPop_form_l { flex-shrink: 0; width: 22px; color: #FF0202; font-weight: bold; font-size: 20px; }


      select {
        width: 100%; height: 46px; background: #EFEFEF; border: none; border-radius: 4px; box-sizing: border-box; padding: 0 10px; transition: .3s; overflow: hidden; color: #666666; font-size: 14px;
        &.pPop_havePro { color: #061829;  margin-right: 20px;
          // style={{ color:  ? '#061829' : '#666666' }}
        }
        &.pPop_haveCity { width: 0; padding: 0 }
        // , width: this.state.nowProvince ? '100%' : 0, padding: this.state.nowProvince ? '10px' : 0
        
      }

      input { background: #EFEFEF; flex-grow: 1; height: 48px; font-size: 16px; border-radius: 2px; border-radius: 2px; box-sizing: border-box; padding: 0 10px;
        &::placeholder { color: #666666 }
      }
      .pPop_form_code { padding: 0 20px; height: 48px; text-align: center; line-height: 48px; color: #fff; border-radius: 2px; background: #0274FF; flex-shrink: 0; margin-left: 20px;  transition: .3s; cursor: pointer; transition: .5s; }
    }

    .pPop_sub { background: #0274FF; width: 350px; height: 56px;  text-align: center; line-height: 56px; font-size: 16px; font-weight: bold; border-radius: 2px; color: #fff; margin: 60px auto 10px; cursor: pointer; }

    .pPop_prompt { text-align: center; color: #8A8A8A; font-size: 12px }
  }
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      appearance: none; 
      margin: 0; 
  }
  /* 火狐 */
  input[type="number"]{
      -moz-appearance:textfield;
  }
}


// toast弹窗

.pToast {
  position: fixed; top: 30%; left: 0; right: 0; justify-content: center;  display: flex; z-index: 99999; animation: pToastshow .5s;
  span { display: block; padding: 12px 16px; background: rgba(0, 0, 0, 0.6); border-radius: 4px; line-height: 20px; color: #fff; }
}
@keyframes pToastshow {
  0% {  transform: translateY(-5px); opacity: 0; }
  100% { transform: translateY(0); opacity: 1; }
}


// dialog弹窗
.dialogPop {position: fixed; top: 0; left: 0; z-index: 999; right: 0; bottom: 0; display: flex; align-items: center; justify-content: center; background: rgba(0,0,0,.5);
  
  .dialogPop_con {
    padding: 20px 15px; width: 420px; box-sizing: border-box; border-radius: 4px; background: #fff; position: relative;
    animation: showDia .5s;
  }
  .dialogPop_cancel{ font-size: 26px; color: #888888; position: absolute; right: 0; top: 0; padding: 5px 10px; line-height: 1;
    &:after { content: "\2A2F" }
  }
  .dialogPop_title { font-size: 15px; }
  .dialogPop_text { padding: 15px 0; color: #535353; font-size: 15px;}
  .dialogPop_btn { display: flex; justify-content: flex-end; 
    div { padding: 8px 16px; background: #409eff;border-radius: 4px; color: #fff; cursor: pointer; }
  }
}

@keyframes showDia{
  0% {  transform: translateY(-20px); opacity: 0; }
  100% { transform: translateY(0); opacity: 1; }
}