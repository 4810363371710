$maxWidth: 1200px;
$mainColor: #0274FF;

.pChannel {
  .pChannel_banner { height: 800px; width: 100%; position: relative;
    img { position: absolute; left: 0; top: 0; width: 100%; height: 100%;object-fit: cover; }

    .pChannel_banner_con { position: relative; z-index: 1; padding-top: 300px; text-align: center; color: #fff; }
    h6 { font-size: 38px; }
    .pChannel_banner_text { font-size: 18px; margin: 16px 0 66px; }
    .pChannel_banner_btn { width: 200px; height: 52px; line-height: 52px; text-align: center; background: #FF8702; margin: 0 auto; cursor: pointer; font-size: 16px; border-radius: 2px; }
  }


  .pChannel_title { font-size: 28px; font-weight: bold; margin: 150px 0 50px; text-align: center; position: relative; }



  .pChannel_good {
    display: flex; width: $maxWidth; margin: 0 auto; justify-content: space-between;
    li { text-align: center;
      img { width: 120px; height: 120px; }
      h6 { margin-top: 12px; font-size: 18px;  }
    }
  }

  .pChannel_way { width: 100%; padding-bottom: 60px; background: #F5F9FF; margin-top: 90px;
    .pChannel_title { padding: 60px 0 50px; margin: 0; }
    ul { display: flex; justify-content: space-between; width: $maxWidth; margin: 0 auto; 
      li { width: 380px; height: 368px;
        img { width: 100%; height: 228px; object-fit: cover; }
        .pChannel_way_con { box-sizing: border-box; padding: 0 20px; color: #fff; }
        h6 { padding: 18px 0 14px; font-size: 24px; position: relative;
          &::after { content: ''; position: absolute; left: 0; bottom: 0; width: 296px; background: #fff; height: 1px; }
        }
        p { padding-top: 14px; width: 300px; }
        &:nth-of-type(1) { background: #00B571; }
        &:nth-of-type(2) { background: #0274FF; }
        &:nth-of-type(3) { background: #FF8702; }
      }
    }
  }


  .pChannel_step {
    display: flex; align-items: center; justify-content: space-between; width: $maxWidth; margin: 0 auto 90px;
    .pChannel_step_item {
      position: relative; width: 160px; height: 160px;
      img { width: 100%; height: 100%; }
      p { position: absolute; left: 0; bottom: 24px; width: 100%; text-align: center; }
    }
    .pChannel_step_next { width: 30px; height: 50px; }
  }


  .pChannel_from { width: 100%; padding: 60px 0 44px; background: linear-gradient(to bottom, #F9FCFF, #F0F7FF); 
    form { width: 578px; margin: 0 auto; position: relative; background: #fff; box-sizing: border-box; padding: 0 30px 40px; box-sizing: 0 0 18px 0 rgba(148, 148, 148, 0.14);
      .pChannel_from_three { position: absolute; width: 0; height: 0;  border: 40px solid transparent; border-top: 40px solid $mainColor; left: -40px; top: -40px; transform: rotate(135deg); }

      .pChannel_from_title { padding: 32px 0 36px; text-align: center; font-size: 28px; }

      .pChannel_from_item {
        display: flex; align-items: center; width: 100%; border: 1px solid #0274FF; border-radius: 2px; height: 56px; line-height: 56px; margin-bottom: 16px; position: relative;
        
        // animation-delay: .3s!important;

        // select { border: none; flex-grow: 1; height: 100%; appearance:none; outline: none; }
        // select { border: none; flex-grow: 1; height: 100%; appearance:none; outline: none; position: absolute; left: 0; top: 0; padding-left: 112px; opacity: 0; width: 100%; }
        
        &.pChannel_from_itemHy { cursor: pointer;
          input { cursor: pointer; background: none; }
          i { width: 0; height: 0;  border: 8px solid transparent; border-top: 10px solid $mainColor; position: absolute; right: 22px; top: 22px; cursor: pointer; }
          ul { position: absolute; left: 0; top: 58px; right: 0; background: #fff; border-radius: 4px; box-shadow: 0 0 3px 1px #e9e9e9; max-height: 240px; overflow-y: auto;
            li { padding: 0 12px; border-bottom: 1px solid #e9e9e9; cursor: pointer; 
              &:hover { background: #ccc; }
            }
          }
        }

        >div { width: 112px; display: block; box-sizing: border-box; letter-spacing: 1px; padding-left: 20px; 
          span { color: #FF0202; font-size: 22px;  }
        }

        input { height: 100%; flex-grow: 1; border: none; outline: none; 
          &::placeholder { color:#666666 }
        }

        

        
      }
      .pChannel_from_item_alert { color: #FF0202; padding-bottom: 10px; position: relative; top: -8px; padding-left: 112px; }

      input[type="submit"] { background: $mainColor; color: #fff; width: 300px; height: 42px; text-align: center; line-height: 42px; color: #fff; border-radius: 2px; margin: 40px auto 0; outline: none; border: none; display: block; cursor: pointer;}

      input[type="number"]::-webkit-outer-spin-button,
      input[type="number"]::-webkit-inner-spin-button {
          -webkit-appearance: none;
          appearance: none; 
          margin: 0; 
      }
      /* 火狐 */
      input[type="number"]{
          -moz-appearance:textfield;
      }

    }
    .pChannel_from_img {
      width: $maxWidth; margin: 26px auto 0; height: 26px;
      img { width: 342px; height: 26px; float: right; }
    }
  }
  

}