$maxWidth: 1200px;
$mainColor: #0274FF;
.pHome {
  .pHome_bgtext { position: absolute; left: 0; right: 0;font-size: 160px; text-align: center; bottom: 0;  height: 130px; overflow: hidden; color: rgba(255,255,255,.08) }
  .pHome_main { width: $maxWidth; margin: 0 auto; }
  .pHome_title { font-size: 28px; font-weight: bold; margin: 160px 0 70px; text-align: center; position: relative; z-index: 1; }

  .pPhome_opera { position: relative; height: 750px;
    .pPhome_opera_lbg { width: 100%; height: 568px; background: #041325; }
    .pPhome_opera_rbg { position: absolute; right: 0; width: 55%; top: 0; height: 700px; object-fit: cover; }
    .pPhome_opera_main { position: absolute; width: 1200px; margin-left: -600px; left: 50%; top: 0; z-index: 1; color: #fff; 
      &::after { position: absolute; content: ''; left: -60px; top: 120px; height: 600px; background: #fff; width: 180px; z-index: -1; }
    }
    .pPhome_opera_title { text-align: center; padding: 68px 0; font-size: 28px; }
    .pPhome_opera_mes { width: 652px;  background: #FF4D00; box-sizing: border-box; padding: 0 62px 52px; }
    .pHome_way_title { font-size: 18px; font-weight: bold; padding-bottom: 8px; padding-top: 30px;  }
      // .pHome_way_text { color: #666666;}
      .pHome_way_btn { padding-top: 80px; display: flex;
        div {
          width: 140px; height: 42px; text-align: center; line-height: 42px; border: 1px solid; border-radius: 2px; color: #fff; cursor: pointer;
          &:nth-of-type(1) { background: #fff;  margin-right: 20px; color: #FF4D00 }
        }
        
      }
  }
  .pHome_rz_title { margin: 100px 0 160px; }
  .pHome_rz { position: relative; height: 560px; background: #0A213A; color: #fff;
    .pHome_rz_main { width: 1200px; margin: 0 auto; display: flex; align-items: flex-end; top: -100px; height: 556px; position: absolute; left: 50%; margin-left: -600px;  }
    .pHome_rz_l { width: 712px; height: 514px; padding: 21px; background: #fff; flex-shrink: 0; margin-right: 66px;
      img { width: 100%; height: 100%; object-fit: cover; }
    }
    .pHome_way_title { font-size: 18px; font-weight: bold; padding-bottom: 8px; padding-top: 30px;  }
      // .pHome_way_text { color: #666666;}
      .pHome_way_btn { padding-top: 42px; display: flex;
        div {
          width: 140px; height: 42px; text-align: center; line-height: 42px; border: 1px solid $mainColor; border-radius: 2px; color: $mainColor; cursor: pointer;
          &:nth-of-type(1) { background: $mainColor; color: #fff; margin-right: 20px; }
        }
      }
  }

  // .pHome_way { display: flex; align-items: center; justify-content: space-between; 
  //   .pHome_way_main { width: 374px;
  //     .pHome_way_title { font-size: 18px; font-weight: bold; padding-bottom: 8px; padding-top: 40px;  }
  //     .pHome_way_text { color: #666666;}
  //     .pHome_way_btn { padding-top: 20px; display: flex;
  //       div {
  //         width: 140px; height: 42px; text-align: center; line-height: 42px; border: 1px solid $mainColor; border-radius: 2px; color: $mainColor; cursor: pointer;
  //         &:nth-of-type(1) { background: $mainColor; color: #fff; margin-right: 20px; }
  //       }
        
  //     }
  //   }
  //   .pHome_way_pic {
  //     width: 615px; height: 400px; object-fit: cover;
  //   }
  // }

  .pHome_update {
    display: flex; justify-content: space-between; 
    &_item {width: 380px; height: 370px; box-shadow: 0 0 18px 0 rgba(148, 148, 148, 0.14); }
    img { width: 100%; height: 288px; object-fit: cover; }
    h6 { text-align: center; padding-top: 32px; font-size: 18px; }
  }


  .pHome_serverbox { background: url('../img/home/bg-e@2x.png') no-repeat 100%; height: 460px; margin-top: 100px; position: relative;
    .pHome_title { margin: 0; padding: 60px 0; color: #fff }  
    .pHome_bgtext { height: 100px; }
  }

  .pHome_server {
    display: flex; justify-content: space-around; text-align: center; width: 1200px; margin: 0 auto;
    &_item { background: #fff; width: 276px; height: 226px;
      img { width: 100px; height: 100px; margin: 0 auto; padding: 24px 0 14px; }
      h6 { font-size: 18px; padding: 0 0 10px; }
      p { color: #666666 }
    }
  }

  .pHome_slide {
    width: $maxWidth; margin: 0 auto; position: relative;
    .pHome_slide_left, .pHome_slide_right { position: absolute; width: 44px; height: 44px; margin-top: -22px; top: 50%; cursor: pointer; }
    .pHome_slide_left { left: -88px; }
    .pHome_slide_right { right: -88px; transform: rotate(180deg); }
    .swiper-wrapper p { display: flex; padding-bottom: 10px;
      span { flex-shrink: 0; }
    }
  }

  .pHome_achieve_item {
    height: 334px; width: 100%; position: relative;
    .pHome_achieve_bg { width: 100%; height: 100%; object-fit: cover; }
    .pHome_achieve_main { position: absolute; bottom: 0; box-sizing: border-box; background: rgba(0,0,0,.7); color: #fff; display: flex; flex-direction: column;  height: 0; overflow: hidden; box-sizing: border-box;z-index: 2; transition: .3s;padding: 0;
      div { font-size: 20px;font-weight: bold; padding-bottom: 24px; flex-shrink: 0; }
      p { flex-grow: 1; }
      img { width: 50px; height: 50px; flex-shrink: 0; }
    }
    .pHome_achieve_des { position: absolute; bottom: 0; height: 80px; display: flex; align-items: center; color: #fff; font-size: 20px; box-sizing: border-box; padding: 0 18px; overflow: hidden; transition: .1s; background: rgba(0,0,0,.3); width: 100%;
      img { width: 50px; height: 50px; margin-right: 10px; }
    }
    &:hover {
      .pHome_achieve_main { height: 100%;   padding: 26px 18px 20px;  }
      .pHome_achieve_des { height: 0; padding: 0; }
    }
    //
  }

  // .pHome_achieve {
  //   display: flex; justify-content: space-between; text-align: center;
  //   &_item { width: 224px;  box-shadow: 0 0 18px 0 rgba(148, 148, 148, 0.14); box-sizing: border-box; padding: 30px 20px 20px;
  //     img { width: 84px; height: 84px; margin: 0 auto; }
  //     h6 { font-size: 18px; padding: 22px 0 10px; }
  //     p { color: #666666; text-align: left; }
  //   }
  // }

  .pHome_map {width: 100%; background: #F7FBFF;  position: relative;
		.pHome_map_com {
      position: relative; height: 100%; width: 1200px; margin: 0 auto;
      li { position: absolute; left: 50%; top: 50%;
        div {width: 14px; height: 14px;   border-radius: 50%; background: radial-gradient(rgba(255, 255, 255, 0.3), rgba(255, 77, 0, 0.3)); box-sizing: border-box; padding: 4px; z-index: 1;
          i { background: rgb(155, 155, 155); width: 100%; height: 100%; border-radius: 50%; background: rgba(255, 77, 0, 1); display: block; }
        }
        
        p { position: absolute; bottom: 14px; left: -30px; margin-left: 50%; width: 60px;  z-index: 1; font-size: 12px; box-sizing: border-box; text-align: center;  letter-spacing: 0;  background: url('../img/home/locat.png')no-repeat bottom; background-size: 24px 100%; color: #fff; overflow: hidden; height: 0; animation: moreheight .5s forwards; line-height: 24px;
          // img { position: absolute; left: 0; top: 0; width: 100%; height: 100%; z-index: -1; }
        }
        span { position: absolute; left: 16px; top: 0; white-space: nowrap; z-index: 9; }
        &:after { content: '';
          position: absolute;
          width: 14px;
          height: 14px;
          box-shadow: inset rgba(255, 77, 0, 1) 0 0 3px;
          border-radius: 50%;
          top: 0;
          left: 0; opacity: 0; animation: mybig 1s infinite linear; }
          &:nth-of-type(1) {left: 22%; top: 59%;}
          
          &:nth-of-type(2) {left: 52%; top: 64%;}
          &:nth-of-type(3) {left: 48%; top: 72%;}
          
          
          &:nth-of-type(4) {left: 71%; top: 46.4%;}
        
        &:nth-of-type(6) {left: 66%; top: 59%;}
        &:nth-of-type(7) {left: 65.5%; top: 64%;}
        
        &:nth-of-type(8) {left: 64.5%; top: 74%;}
        &:nth-of-type(5) {left: 73%; top: 56%;}
        
        &:nth-of-type(9) {left: 29%; top: 61%;}
        &:nth-of-type(10) {left: 33%; top: 63%;}
        &:nth-of-type(11) {left: 43%; top: 40%;}
        &:nth-of-type(12) {left: 85%; top: 20%;}
        &:nth-of-type(13) {left: 83%; top: 31%;}
        &:nth-of-type(14) {left: 80%; top: 31%;}
        &:nth-of-type(15) {left: 72%; top: 43%;}
        &:nth-of-type(16) {left: 23%; top: 44%;}
        &:nth-of-type(17) {left: 73%; top: 45%;}
        &:nth-of-type(18) {left: 71%; top: 51%;}
        &:nth-of-type(19) {left: 52%; top: 53%;}
        &:nth-of-type(20) {left: 60%; top: 57%;}
        &:nth-of-type(21) {left: 54%; top: 60%;}
        &:nth-of-type(22) {left: 55%; top: 61%;}
        &:nth-of-type(23) {left: 15%; top: 38%;}
        &:nth-of-type(24) {left: 45%; top: 69%;}
        &:nth-of-type(25) {left: 18%; top: 44%;}
        &:nth-of-type(26) {left: 43%; top: 74%;}
        &:nth-of-type(27) {left: 51%; top: 67%;}
        &:nth-of-type(28) {left: 59%; top: 65%;}
        &:nth-of-type(29) {left: 33%; top: 35%;}
        &:nth-of-type(30) {left: 30%; top: 26%;}
        &:nth-of-type(31) {left: 68%; top: 68%;}
        &:nth-of-type(32) {left: 71%; top: 67%;}
        &:nth-of-type(33) {left: 69%; top: 70%;}
        &:nth-of-type(34) {left: 61%; top: 71%;}
        &:nth-of-type(35) {left: 58%; top: 73%;}
        &:nth-of-type(36) {left: 55%; top: 73%;}
        &:nth-of-type(37) {left: 43%; top: 40%;}
        &:nth-of-type(38) {left: 68%; top: 73%;}
        &:nth-of-type(39) {left: 74%; top: 64%;}
        &:nth-of-type(40) {left: 59%; top: 76%;}
        &:nth-of-type(41) {left: 62%; top: 74%;}
        &:nth-of-type(42) {left: 24%; top: 47%;}
        &:nth-of-type(43) {left: 77%; top: 69%;}
      }
		}
    .pHome_map_mes { position: absolute; left: 50%; width: 1000px;  bottom: -50px; text-align: center; height: 140px; display: flex;  align-items: center; background: linear-gradient(to right, rgba(2, 116, 255, 0.1), rgba(2, 116, 255, 1), rgba(2, 116, 255, 1), rgba(2, 116, 255, 0.1)); font-size: 18px; color: #fff; justify-content: center; margin-left: -500px; }
    // img { height: 900px;}
    img { width: 100% }
    
  }
}
@keyframes mybig {
  0%{opacity:0;-webkit-transform:scale(.1);transform:scale(.1)}
  5%{opacity:1}
  to{opacity:0;-webkit-transform:scale(3);transform:scale(4)}
}

@keyframes moreheight {
  0% { height: 0; opacity: 0;}
  60% { height: 60px; opacity: 1;}
  80% { height: 20px; opacity: 1;}
  100% { height: 36px; opacity: 1;}
}


