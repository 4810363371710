body { min-width: 1250px; font-size: 14px; color: #061829;font-family: '微软雅黑'; }
* { margin: 0; padding: 0; }
ul, li { list-style: none; }
img { display: block; }
h6 { letter-spacing: 2px; }
p { letter-spacing: 1px; }
input { border: none; }

// .appBox { padding-top: 70px; } 
.btn {
  &:hover { opacity: .8; }
}
.textBtn {
  &:hover { opacity: .4; }
}




.newsdl { width: 1200px; margin: 0 auto;
  .newsdl_title { padding: 100px 0 50px; font-size: 22px; text-align: center; }
  .newsdl_time { text-align: center; font-size: 14px; padding-bottom: 30px; }
  .newsdl_con { padding: 20px 0 80px; }
}

.robot { position: fixed; right: 80px; bottom: 60px;width: 90px; z-index: 9; cursor: pointer; }


.title_subtitle { position: absolute; color: rgba(6, 24, 41, .06); z-index: -1; left: 50%; width: 800px; margin-left: -400px; text-align: center; font-size: 80px; top: -68px; }


.pHome_slide_left, .pHome_slide_right, .pExample_slide_left, .pExample_slide_right { display: block; }
@media (max-width: 1200px) { 
  .pHome_slide_left, .pHome_slide_right, .pExample_slide_left, .pExample_slide_right { display: none; }
}
