.newsPage {
  .newsPage_banner { width: 100%; height: 800px; object-fit: cover; }
  .news_main { width: 1200px; margin: 0 auto; position: relative; top: -110px; background: #fff; box-shadow: 0 0 18px 0 rgba(148, 148, 148, 0.14); border-radius: 4px; padding: 60px 40px; box-sizing: border-box; 
  }
  .new_title {
    display: flex; align-items: center; font-size: 18px; padding-left: 100px;
    div { margin-right: 40px; cursor: pointer; }
    .actTitle { color: #0274FF; font-weight: bold; font-size: 24px; }
  }
  .news_list { 
    li {
      padding: 60px 90px 60px 100px; box-sizing: border-box; border-bottom: 1px solid #F1EEEE;
      .news_list_con { display: flex; align-items: center; 
        img { width: 246px; height: 148px; object-fit: cover; margin-right: 40px; flex-shrink: 0; }
      }
      .new_list_r {
        flex-grow: 1; min-height: 148px; display: flex; flex-direction: column;
        .new_list_title { font-size: 17px; font-weight: bold; }
        .new_list_des { color: #666666; font-size: 13px; flex-grow: 1; padding: 12px 0; box-sizing: border-box; }
        .new_list_btn { width: 140px; height: 42px; line-height: 42px; text-align: center; background: #0274FF; color: #fff; border-radius: 2px; cursor: pointer; }
      }
      .news_list_time{ margin-top: 11px; color: #061829 }
    }
  }

  .news_page { display: flex; justify-content: center; padding: 80px 0 0; }
}
.page-container {
  display: flex;
  li {
    list-style: none;
    padding: 0 10px;
    cursor: pointer;
    height: 30px;
    line-height: 30px;
    margin-right: 8px;
    background: #F1EEEE;
    color: #666666;
    &:nth-last-of-type(1) {font-size: 14px; color: #B3B1B1;
      &::after { content: '\25B6' }
    }
    &:nth-of-type(1) {font-size: 14px; color: #B3B1B1; transform: rotate(180deg);
      &::after { content: '\25B6' }
    }
    &:hover { opacity: .6; }
    &.nomore {opacity: .8; cursor: no-drop; }
  }
  .activePage {
    color: #fff!important;
    background: #0274FF;
  }
}