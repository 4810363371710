$maxWidth: 1200px;
$mainColor: #0274FF;

.pExample {
  .swiper-container {
    width: $maxWidth;
    height: 430px;
    // .swiper-wrapper { width: 1200px; margin: 0 auto; overflow: hidden; }
    .swiper-slide { flex-shrink: 0; width: 380px; box-shadow: 0 0 18px 0 rgba(148, 148, 148, 0.14); box-sizing: border-box; padding: 20px 40px 0; height: 416px; margin-right: 30px;
      img { width: 120px; height: 120px; border-radius: 50%; margin: 0 auto; }
      h6 { font-size: 20px; padding: 40px 0 10px; text-align: center; }
      &:nth-of-type(3n + 1) { border-top: 20px solid #00B571; }
      &:nth-of-type(3n + 2) { border-top: 20px solid #0274FF; }
      &:nth-of-type(3n) { border-top: 20px solid #FF8702; }
    }
  }
  .pExample_banner { height: 800px; width: 100%; position: relative;
    img { position: absolute; left: 0; top: 0; width: 100%; height: 100%;object-fit: cover; }
    .pExample_banner_con { position: relative; z-index: 1; padding-top: 98px; text-align: center; color: #fff; }
   h6 { font-size: 50px; }
    .pExample_banner_text { font-size: 24px; margin: 30px 0 50px; }
    .pExample_banner_btn { width: 160px; height: 52px; line-height: 52px; text-align: center; background: #FF8702; margin: 0 auto; cursor: pointer; font-size: 20px; border-radius: 2px; }
  }

  .pExample_title { font-size: 28px; font-weight: bold; margin: 150px 0 50px; text-align: center; position: relative; }

  .pExample_slide {
    width: $maxWidth; margin: 0 auto; position: relative;
    .pExample_slide_left, .pExample_slide_right { position: absolute; width: 44px; height: 44px; margin-top: -22px; top: 50%; cursor: pointer; }
    .pExample_slide_left { left: -88px; }
    .pExample_slide_right { right: -88px; transform: rotate(180deg); }
    .swiper-wrapper p { display: flex; padding-bottom: 10px;
      span { flex-shrink: 0; }
    }

    // .pExample_slide_list { width: 100%; overflow: hidden; }
    // ul { display: flex; align-items: center; position: relative; left: 0; transition: .1s;
    //   li { flex-shrink: 0; width: 380px; box-shadow: 0 0 18px 0 rgba(148, 148, 148, 0.14); box-sizing: border-box; padding: 20px 40px 0; height: 416px; margin-right: 30px;
    //     img { width: 120px; height: 120px; border-radius: 50%; margin: 0 auto; }
    //     h6 { font-size: 20px; padding: 40px 0 10px; text-align: center; }
    //     &:nth-of-type(3n + 1) { border-top: 20px solid #00B571; }
    //     &:nth-of-type(3n + 2) { border-top: 20px solid #0274FF; }
    //     &:nth-of-type(3n) { border-top: 20px solid #FF8702; }
    //   }
    // }
  }
  
}