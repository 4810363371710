$maxWidth: 1200px;
$mainColor: #0274FF;

.pAbout { background: #FEFFFF;
  .pAbout_banner {
    width: 100%; height: 800px; position: relative; color: #fff;
    img { position: absolute; left: 0; top: 0; width: 100%; height: 100%;object-fit: cover; }
    .pAbout_banner_con {
      position: relative; z-index: 1;
      h6 { font-size: 38px; padding: 220px 0 16px; text-align: center; }
      .pAbout_banner_text { width: 1130px; font-size: 18px; margin: 0 auto; }
    }
  }

  .pAbout_title { font-size: 28px; margin: 120px 0 60px; text-align: center; position: relative; }

  // .pAbout_devbox { width: 1400px; margin: 0 auto; overflow: auto; }


  .pAbout_dev {
     position: relative; margin: 0 auto 60px; width: 20px; padding: 80px 0 30px;

    .pAbout_dev_outline { position: absolute; left: 7px; width: 4px; top: 0; bottom: 0;  background: linear-gradient(to bottom, rgba(239, 239, 239, 0), #DDDDDD, rgba(218, 218, 218, 0)); margin-top: -2px; border-radius: 10px; }

    li.pAbout_dev_item {
      position: relative;width: 18px; height: 18px; z-index: 1; margin-bottom: 126px;
      .pAbout_dev_dot {
        border: 1px solid $mainColor; width: 100%; height: 100%; border-radius: 50%; padding: 3px; box-sizing: border-box;
        i {
          display: block; width: 100%;  height: 100%; background: $mainColor; border-radius: 50%;
        }
      }

      .pAbout_dev_year { position: absolute; width: 60px; text-align: center; left: 50%; margin-left: -30px; }
      .pAbout_dev_line { position: absolute;  height: 0; width: 96px; top: 9px;  right: 0; border-top: 2px dashed #ccc;
        &::after{ content: ''; position: absolute; height: 3px; bottom: 0; width: 100%; background: linear-gradient(to right, rgba(255,255,255,.8), rgba(255,255,255,.1)); z-index: 1; }
      }

      
      .pAbout_dev_text { position: absolute;
        box-sizing: border-box;
        width: 420px;
        right: 540px;
        // border: 1px solid #ddd;
        box-shadow: 0 0 18px 0 rgba(148, 148, 148, 0.14);
        border-radius: 3px;
        padding: 20px 10px 20px 20px;
        font-size: 14px;
        background-color: #fff;
        top: -40px;
        position: relative;
        h6 { font-size: 18px; padding-bottom: 10px; }
      }
        // .pAbout_dev_text::before {
        //     content: '\25B6';
        //     position: absolute;
        //     right: -10px; top: 30%;
        // }
      &:nth-of-type(2n + 1) { 
        .pAbout_dev_year { top: 26px; }
        .pAbout_dev_text { left: 140px; }
        // .pAbout_dev_text::before {  transform: rotate(180deg);transform-origin: 0 50%; left: 0;  }
        .pAbout_dev_line { left: 26px; 
          &::after{  background: linear-gradient(to left, rgba(255,255,255,.8), rgba(255,255,255,.1));  }
        }
      } 
      &:nth-of-type(2n) { 
        .pAbout_dev_year { bottom: 26px }
        .pAbout_dev_line { right: 26px; }
        
        // .pAbout_dev_line { top: 26px; transform: rotate(180deg); }
        // .pAbout_dev_text::before { }
      } 
      &:nth-of-type(4n + 1), &:nth-of-type(4n + 2) {
        .pAbout_dev_text { background: $mainColor; color: #fff;  }
        // .pAbout_dev_text::before { }
      }
    }
  }

  // .pAbout_dev {
  //   display: flex; justify-content: space-between; min-width: 900px; margin: 210px auto 320px; position: relative; justify-content: space-between; max-width: 1300px; width: 70vw; left: 100px;

  //   .pAbout_dev_outline { position: absolute; left: -60px; top: 50%; height: 4px; right: -60px; background: linear-gradient(to right, rgba(239, 239, 239, 0), #DDDDDD, rgba(218, 218, 218, 0)); margin-top: -2px; border-radius: 10px; }

  //   li.pAbout_dev_item {
  //     position: relative;width: 18px; height: 18px; z-index: 1;
  //     .pAbout_dev_dot {
  //       border: 1px solid $mainColor; width: 100%; height: 100%; border-radius: 50%; padding: 3px; box-sizing: border-box;
  //       i {
  //         display: block; width: 100%;  height: 100%; background: $mainColor; border-radius: 50%;
  //       }
  //     }

  //     .pAbout_dev_year { position: absolute; width: 60px; text-align: center; left: 50%; margin-left: -30px; }
  //     .pAbout_dev_line { position: absolute; left: 50%; width: 2px; margin-left: -1px; height: 116px; }

      
  //     .pAbout_dev_text { position: absolute;
  //       box-sizing: border-box;
  //       width: 260px;
  //       right: 260px;
  //       border: 1px solid #ddd;
  //       border-radius: 3px;
  //       padding: 20px 10px 20px 20px;
  //       font-size: 14px;
  //       background-color: #fff;
  //       position: relative;}
  //       .pAbout_dev_text::before {
  //           content: '';
  //           position: absolute;
  //           width: 6px; height: 6px;
  //           background: linear-gradient(to top, #cecece, #cecece) no-repeat, linear-gradient(to right, #cecece, #cecece) no-repeat, linear-gradient(135deg, #fff, #fff 6px, hsla(0,0%,100%,0) 6px) no-repeat;
  //           background-size: 6px 1px, 1px 6px, 6px 6px;
  //           transform: rotate(135deg);;
  //           right: -3px; top: 70%;
  //       }
  //     &:nth-of-type(2n + 1) { 
  //       .pAbout_dev_year { top: 26px; }
  //       .pAbout_dev_text { top: -210px; }
  //       .pAbout_dev_line { bottom: 26px }
  //     } 
  //     &:nth-of-type(2n) { 
  //       .pAbout_dev_year { bottom: 26px }
  //       .pAbout_dev_text { top: 70px; }
  //       .pAbout_dev_line { top: 26px; transform: rotate(180deg); }
  //       .pAbout_dev_text::before { top: 30%;  }
  //     } 
  //     &:nth-of-type(4n + 2), &:nth-of-type(4n + 3) {
  //       .pAbout_dev_text { background: $mainColor; color: #fff;}
  //       .pAbout_dev_text::before { background: $mainColor; }
  //     }
  //     &:nth-last-of-type(1) { margin-right: 0; }
  //   }
  // }
  .pAbout_dev_map { position: absolute; left: 50%; margin-left: -580px; top: 0px; width: 1048px; height: 702px; z-index: 1; background: url('../img/about/about_map.png'); background-size: 100%; z-index: -1;
  }
}