$maxWidth: 1200px;
$mainColor: #0274FF;


.pProduct {
  .swiper-container {
    width: 100%;
    height: 100%;
  }  
  .swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
    left: 50%;
    width: 1200px;
    display: flex;
    margin-left: -600px;
  }
  .swiper-pagination-bullet {
    width: 20px; height: 3px; background: #D8D8D8; border-radius: 1px; opacity: 1;
  }
  .swiper-pagination-bullet-active {
    width: 6px;
  }
  // .swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
  //   width: 6px; height: 3px; background: #D8D8D8; border-radius: 0;
  // }
  .pProduct_title { font-size: 28px; font-weight: bold; margin: 150px 0 50px; text-align: center; }
  
  .pProduct_itembox { background: #F7FBFF; padding: 0 0 60px; width: 100%; margin-top: 130px;
    .pProduct_title { padding: 60px 0 50px;  margin: 0;}
    .pProduct_item {
      .pProduct_item_l {
        align-items: flex-start;
      }
    }
  }

  .pProduct_item { display: flex; justify-content: space-between; align-items: center; width: $maxWidth; margin: 0 auto; 
    .pProduct_item_r { width: 616px; height: 400px; object-fit: cover; }
    .pProduct_item_l {
      text-align: right; display: flex; flex-direction: column; align-items: flex-end;
      h6 { font-size: 20px; margin-bottom: 42px; }
      img { width: 90px; height: 90px; }
      .pProduct_item_l_subt { font-weight: bold; font-size: 18px; padding: 8px 24px 10px;  }
      i { display: block; width: 0; height: 0;  border-top: 18px solid $mainColor; border-right: 10px solid transparent;border-bottom: 18px solid transparent;border-left: 10px solid transparent; margin: 0 32px 30px; }
      p { color: #666666; margin-bottom: 10px;
        &:nth-last-of-type(1) { margin-bottom: 0; }
      }
    }
  }
}
