$maxWidth: 1200px;
$mainColor: #0274FF;
.pBanner { height: 800px; position: relative;
  .pbanner_bg { width: 100%; height: 100%; object-fit: cover; position: absolute; left: 0; top: 0; }
  .pbanner_con {
    width: $maxWidth; margin: 0 auto; box-sizing: border-box; padding-top: 220px; position: relative; z-index: 1;

    &_title { font-size: 38px; font-weight: bold; letter-spacing: 3px; 
      img { height: 32px; }
    }
    &_text { font-size: 18px; max-width: 368px; padding-top: 25px; letter-spacing: 2px; 
      p { padding-bottom: 12px; }
    }
    &_go { width: 184px; height: 52px; background: #FF4D00; text-align: center; border-radius: 2px; line-height: 52px; color: #fff; margin-top: 50px; cursor: pointer;}
    &_call { margin-top: 12px; }
  }
}