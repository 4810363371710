$maxWidth: 1200px;
$mainColor: #0274FF;
.pHead {
  height: 70px;  position: fixed; top: 0; z-index: 999; background: rgba(0, 0, 0, 0.6); width: 100%;overflow: auto;
  .pHead_con { display: flex; align-items: center; justify-content: space-between; max-width: 1200px; margin: 0 auto; height: 100%; }
  .phead_l { width: 160px; }
  .phead_r {
    display: flex; align-items: center;    flex-shrink: 0;
    ul {
      display: flex;
      li { padding: 20px; cursor: pointer; color: #fff;
        &.active { color: $mainColor }
      }
    }
  }

  .phead_r_btn { display: flex; margin-left: 150px;
    div { padding: 4px 0; border: 1px solid $mainColor; margin-left: 20px; cursor: pointer; border-radius: 2px;  background: $mainColor; color: #fff; width: 106px; text-align: center;
      // &:nth-of-type(2) { background: $mainColor; color: #fff }
    }
    p { color: #fff; padding: 5px 10px; cursor: pointer; }
  }
}
